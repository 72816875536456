import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    SttHeading,
    SttButton,
    SttTranslateHook,
    SttInput,
    SttNumberInput,
    SttGrid
} from '@stt-componentes/core';
import { useSignals } from '@preact/signals-react/runtime';
import { Formik, Field } from 'formik';
import * as yup from 'yup';
import { CAMPOS, CONTEXTO } from '../constantes';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(2)
    },
    titulo: {
        margin: theme.spacing(2)
    },
    formColumn: {
        border: '2px solid',
        borderColor: '#D9D9D9',
        padding: theme.spacing(9),
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    }
}));

const validationSchema = (strings, contexto) => {
    const campos = {
        [CAMPOS.CODIGO]: yup
            .string()
            .nullable()
            .max(44, strings.tamanhoCodigo)
            .min(44, strings.tamanhoCodigo)
            .required(strings.campoObrigatorio)
    };

    if (contexto !== CONTEXTO.TCLE) {
        campos[CAMPOS.IDENTIFICADOR] = yup
            .number()
            .nullable()
            .required(strings.campoObrigatorio)
    }

    if (contexto === CONTEXTO.ANALISE) {
        campos[CAMPOS.IDENTIFICADOR] = yup
            .string()
            .nullable()
            .max(24, strings.tamanhoCodigo24Caracteres)
            .min(24, strings.tamanhoCodigo24Caracteres)
            .required(strings.campoObrigatorio);
    }

    return yup.object().shape(campos);
};

const initialValues = (contexto) => {
    const values = { [CAMPOS.CODIGO]: '' };

    if (contexto !== CONTEXTO.TCLE) {
        values[CAMPOS.IDENTIFICADOR] = '';
    }
    
    return values;
}

const renderIdentificadorField = (contexto, strings) => {
        switch (contexto) {
        case CONTEXTO.TCLE:
            return <></>;
        case CONTEXTO.ANALISE:
            return (
                <Field name={CAMPOS.IDENTIFICADOR}>
                    {({ field, meta }) => (
                        <SttInput
                            label={strings.identificador}
                            error={meta.touched && meta.error ? true : false}
                            helperText={meta.touched && meta.error ? meta.error : undefined}
                            inputProps={{ maxLength: 24 }}
                            {...field}
                        />
                    )}
                </Field>
            );
        default:
            return (
                <Field name={CAMPOS.IDENTIFICADOR}>
                    {({ field, meta }) => (
                        <SttNumberInput
                            label={strings.identificador}
                            error={meta.touched && meta.error ? true : false}
                            helperText={
                                meta.touched && meta.error
                                    ? meta.error
                                    : undefined
                            }
                            inputProps={{ maxLength: 24 }}
                            {...field}
                        />
                    )}
                </Field>
            );
    }
};

const Form = ({ contexto, formRef }) => {
    useSignals();
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const schema = validationSchema(strings, contexto);
    const values = initialValues(contexto);
    const classes = useStyles();
    const navigate = useNavigate();

    return (
        <>
            <Formik
                innerRef={formRef}
                initialValues={values}
                validationSchema={schema}
                onSubmit={(dados) => {
                    const params = new URLSearchParams();
                    for (const key in dados) {
                        if (Object.prototype.hasOwnProperty.call(dados, key)) {
                            params.append(key, dados[key]);                            
                        }
                    }
                    navigate(`/${contexto}?${params.toString()}`);
                }}
            >
                {({ isSubmitting, handleSubmit }) => {
                    return (
                        <form
                            onSubmit={handleSubmit}
                            noValidate
                            className={classes.formColumn}
                        >
                            <SttHeading variant="h2" color="primary" className={classes.titulo}>
                                {strings.consultaAutenticidade.replace('%1', contexto)}
                            </SttHeading>
                            <SttGrid>
                                {renderIdentificadorField(contexto, strings)}
                                <Field name={CAMPOS.CODIGO}>
                                    {({ field, meta }) => (
                                        <SttInput
                                            label={strings.codigo}
                                            error={
                                                meta.touched && meta.error
                                                    ? true
                                                    : false
                                            }
                                            helperText={
                                                meta.touched && meta.error
                                                    ? meta.error
                                                    : undefined
                                            }
                                            inputProps={{ maxLength: 44 }}
                                            {...field}
                                        />
                                    )}
                                </Field>
                            </SttGrid>
                            <SttButton
                                className={classes.button}
                                type="submit"
                                color="primary"
                                disabled={isSubmitting}
                                variant="contained"
                            >
                                {strings.verificar}
                            </SttButton>
                        </form>
                    );
                }}
            </Formik>
        </>
    );
};

export default Form;
