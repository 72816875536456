import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';

const initializeApp = (config) => {
    /* eslint-disable */
    global.gConfig = config;
    const rootElement = document.getElementById('root');
    const root = createRoot(rootElement);
    
    root.render(<App />);
}

const verificarBaseAlternativa = (config) => {
    let subpaths = window.location.pathname;
    if (subpaths && config.layoutsAlternativos) {
        subpaths = subpaths.split("/");

        let layoutAlternativo = null;
        subpaths.forEach((sp) => {
            if (config.layoutsAlternativos.includes(sp)) {
                layoutAlternativo = sp;
                return true;
            }
        });

        if (layoutAlternativo) {
            return fetch(
                `${process.env.PUBLIC_URL}/config/layoutsAlternativos/${layoutAlternativo}/config.stt-${config.config_id}.json`,
                {
                    headers: { "Content-Type": "application/json" },
                }
            ).then((response) => response.json());
        }
    }
    return config;
}

Promise.all([
    fetch(`${process.env.PUBLIC_URL}/config/changelog.json`).then(response => response.json()),
    fetch(`${process.env.PUBLIC_URL}/config/config.json`).then(response => response.json())
])
.then(([changelog, config]) => Promise.all([changelog, verificarBaseAlternativa(config)]))
.then(([changelog, config]) => initializeApp({ ...config, ...changelog }))
.catch(error => { console.error("Erro ao buscar os arquivos de configurações do frontend", error); });

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
