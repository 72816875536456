export const CAMPOS = {
    IDENTIFICADOR: 'identificador',
    CODIGO: 'codigo',
};

export const CONTEXTO = {
    ANALISE: 'analise',
    EXAME: 'exame',
    TCLE: 'tcle',
    APAC: 'apac'
};
